import React, { useEffect, useState } from 'react'
import './Subscriptions.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress, List, ListItem, ListItemIcon } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { toast } from 'react-toastify';
import FirebaseServices from '../../Services/FirebaseServices';
import { useNavigate } from 'react-router-dom';

function Subscriptions() {
  const firebase = new FirebaseServices();
  const [subscriptions, setSubscriptions] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1600 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1600, min: 1150 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1150, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getSubscriptions() {
    setIsLoading(true)
    await firebase.getSubscriptions().then((subscriptionArray: any) => {
      setSubscriptions(subscriptionArray)
      setIsLoading(false)
    }).catch((error) => {
      toast.error("Failed fetch subscriptions")
    });
  }

  async function handleSelect(subscriptionId: string) {
    localStorage.setItem("SelectedSubscription", subscriptionId)
    navigate("/buy")
  }

  return (
    <div className='container text-center my-5'>
      <h1 className='pt-5 page-title'>Battery Subscription</h1>
      <p>Fully Charged Battery 24x7</p>
      {isLoading ?
        <div className='text-center vh-100'>
          <CircularProgress />
        </div>
        :
        <Carousel responsive={responsive} removeArrowOnDeviceType={'superLargeDesktop'} renderArrowsWhenDisabled>
          {subscriptions.map((subscription, index) =>
            <div
              onClick={() => handleSelect(subscription.id)}
              className="subscription-card"
              key={index}
              data-aos="fade-up"
              data-aos-delay={index * 200}
            >
              <div>
                <h1>{subscription?.range} km</h1>
                <h4>LKR {subscription?.price.toLocaleString()}</h4>
                <List>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    Fully charged {subscription?.battery}Kw battery every time
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    LKR {subscription?.extra.toFixed(2)} per extra km
                  </ListItem>
                </List>
              </div>
            </div>
          )}
        </Carousel>
      }
    </div>
  )
}

export default Subscriptions