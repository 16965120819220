import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import React, { useState } from "react";
import KYC from "./KYC";
import Vehicles from "./Vehicles";
import Packages from "./Packages";
// import Payments from "./Payment/Payments";
import "./Buy.css";
// import OptionalUsage from "./RideEarn";
import FirebaseServices from "../../Services/FirebaseServices";
import { toast } from "react-toastify";
// import SMSAuthController from "../../Services/HelperFunctions";
import { useNavigate } from "react-router-dom";

function Buy({ initialStep = 0 }) {
  const firebase = new FirebaseServices();
  // const [isLoading, setIsLoading] = useState(false);
  // const [uid, setUid] = useState("");
  const [activeStep, setActiveStep] = useState(initialStep);
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function saveLead() {
    // setIsLoading(true);
    let customerDetails = JSON.parse(localStorage.getItem("CustomerDetails") as string);
    customerDetails.selectedBike = localStorage.getItem("SelectedBike");
    customerDetails.selectedSubscription = localStorage.getItem("SelectedSubscription");
    console.log("priya saved", customerDetails)

    await firebase
      .newUserLead(customerDetails)
      .then(async (res: any) => {
        toast.success(res.message as string);
        localStorage.clear();
        // const status = await SMSAuthController.sendMessage(customerDetails.contactNumber,customerDetails.firstname );
        // if (status === 200) {
        //   console.log('SMS sent successfully');
        // } else {
        //   console.error('Failed to send SMS');
        // }
        // setIsLoading(false);
        navigate("/");
        return 1;
      })
      .catch((error) => {
        toast.error(error);
        return 0;
      });
  }
  // async function saveData() {
  //   setIsLoading(true);
  //   let customerDetails = JSON.parse(
  //     localStorage.getItem("CustomerDetails") as string
  //   );
  //   customerDetails.selectedBike = localStorage.getItem("SelectedBike");
  //   customerDetails.selectedSubscription = localStorage.getItem(
  //     "SelectedSubscription"
  //   );
  //   let paymentInformation = JSON.parse(
  //     localStorage.getItem("PaymentInformation") as string
  //   );
  //   await firebase
  //     .newUser(customerDetails)
  //     .then(async (res: any) => {
  //       let userId = res.uid;
  //       toast.success(res.message as string);
  //       if (paymentInformation.paymentType === "Full payment") {
  //         paymentInformation.paymentDocId =
  //           localStorage.getItem("PaymentDocId");
  //         paymentInformation.invoiceNo = localStorage.getItem("InvoiceNo");
  //       } else {
  //         paymentInformation.userNICFront = await firebase.uploadFile(
  //           userId,
  //           paymentInformation.userNICFront,
  //           "front"
  //         );
  //         paymentInformation.userNICBack = await firebase.uploadFile(
  //           userId,
  //           paymentInformation.userNICBack,
  //           "back"
  //         );
  //         paymentInformation.guarantorNICFront = await firebase.uploadFile(
  //           userId,
  //           paymentInformation.guarantorNICFront,
  //           "guarantor_front"
  //         );
  //         paymentInformation.guarantorNICBack = await firebase.uploadFile(
  //           userId,
  //           paymentInformation.guarantorNICBack,
  //           "guarantor_back"
  //         );
  //       }

  //       await firebase
  //         .updatePayment(userId, paymentInformation)
  //         .then(async (res) => {
  //           localStorage.clear();
  //           setUid(userId);
  //           await sendSMS(
  //             customerDetails.firstname,
  //             customerDetails.contactNumber
  //           );
  //           toast.success(res as string);
  //           setIsLoading(false);
  //           return 1;
  //         })
  //         .catch((error) => {
  //           toast.error(error);
  //           return 0;
  //         });
  //     })
  //     .catch((error) => {
  //       toast.error(error);
  //       return 0;
  //     });
  // }

  return (
    <div className="container my-5 pt-5">
      <Stepper
        activeStep={activeStep}
        className="stepper"
        orientation="vertical"
      >
        <Step>
          <StepLabel>Choose a vehicle</StepLabel>
          <StepContent>
            <Vehicles handleNext={handleNext} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Choose a battery subscription package</StepLabel>
          <StepContent>
            <Packages handleBack={handleBack} handleNext={handleNext} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Customer details</StepLabel>
          <StepContent>
            <KYC
              handleBack={handleBack}
              handleNext={handleNext}
              saveLead={saveLead}
            />
          </StepContent>
        </Step>
        {/* <Step>
          <StepLabel>Payment Options</StepLabel>
          <StepContent>
            <Payments
              handleBack={handleBack}
              handleNext={() => { handleNext()}}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Ride & Earn</StepLabel>
          <StepContent>
            <OptionalUsage
              isLoading={isLoading}
              userId={uid}
              saveData={saveData}
            />
          </StepContent>
        </Step> */}
      </Stepper>
    </div>
  );
}

export default Buy;
